import { useTranslation } from 'react-i18next';
import BarChart from '../bar-chart/bar-chart';
import { useEffect, useState } from 'react';
import { OptionValue, PeriodType } from '@orascom/api-interfaces';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { GraphData, PerformanceGraphs } from '../../definitions/analytics';
import { errorMessagesHandler, getButtonText } from '@orascom/utils';

export interface DestinationPerformanceProps {
  startDateArg: string;
  endDateArg: string;
  selectedDestinations: OptionValue<string | number>[];
  selectedUnitTypes: OptionValue<string | number>[];
  selectedDestinationsIds: string[];
  selectedUnitTypesIds: string[];
  selectedDuration: PeriodType;
}

export function DestinationPerformance(
  props: Readonly<DestinationPerformanceProps>
) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>();
  const activeTabs = ['Sales Revenue', 'Requests', 'Views'];
  const [destinationPerformance, setDestinationPerformance] =
    useState<PerformanceGraphs>();
  const [activeTabLabels, setActiveTabLabels] = useState<string[]>([]);
  const [activeTabData, setActiveTabData] = useState<GraphData[]>([]);

  useEffect(() => {
    setActiveTab(activeTabs[0]);
  }, []);

  const processTabData = (tab: string, destPerformance: PerformanceGraphs) => {
    let labels: string[] = [];
    let data: GraphData[] = [];

    switch (tab) {
      case 'Sales Revenue':
        labels = destPerformance?.sales_revenue?.map(
          (destination) => destination.name
        );
        data = destPerformance?.sales_revenue?.map((destination) => ({
          x: destination.name,
          y: Number(destination.sales_revenues),
          currency: destination.currency,
        }));
        break;
      case 'Requests':
        labels =
          destPerformance?.requests?.map((destination) => destination.name) ??
          [];
        data =
          destPerformance?.requests?.map((destination) => ({
            x: destination.name,
            y: Number(destination.requests_count),
          })) ?? [];
        break;
      case 'Views':
        labels =
          destPerformance?.views?.map((destination) => destination.name) ?? [];
        data =
          destPerformance?.views?.map((destination) => ({
            x: destination.name,
            y: Number(destination.views_count),
          })) ?? [];
        break;
      default:
        break;
    }

    return { labels, data };
  };

  useEffect(() => {
    if (activeTab && destinationPerformance) {
      const { labels, data } = processTabData(
        activeTab,
        destinationPerformance
      );
      setActiveTabLabels(labels);
      setActiveTabData(data);
    }
  }, [activeTab, destinationPerformance]);

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };
    AnalyticsUtils.getAnalyticsDestinationPerformance(params)
      .then((res) => {
        setDestinationPerformance(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      });
  }, [
    props.startDateArg,
    props.endDateArg,
    props.selectedDestinations,
    props.selectedUnitTypes,
  ]);

  const data = {
    labels: activeTabLabels,
    datasets: [
      {
        label: getButtonText(props.selectedDuration),
        data: activeTabData || [],
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };

  return (
    <BarChart
      description={t('destinationPerformanceDescription')}
      title={t('destinationPerformance')}
      activeTabs={activeTabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      data={data}
      unMaintainAspectRatio
    />
  );
}

export default DestinationPerformance;
