import { Authentication as AuthenticationApi } from '../api/authentication';
import { handleError } from '@orascom/utils';
import {
  Payload,
  SetPasswordInput,
  UserLoginResponse,
} from '@orascom/api-interfaces';

export const storeTokenDetails = (
  payload: Payload<UserLoginResponse>
): void => {
  if (process.env['NX_SHOPPER_ANALYTICS_ACCESS_TOKEN_KEY']) {
    localStorage.setItem(
      process.env['NX_SHOPPER_ANALYTICS_ACCESS_TOKEN_KEY'],
      payload?.data?.token
    );
  }
};

export const removeTokenDetails = (): void => {
  if (process.env['NX_SHOPPER_ANALYTICS_ACCESS_TOKEN_KEY']) {
    localStorage.removeItem(
      process.env['NX_SHOPPER_ANALYTICS_ACCESS_TOKEN_KEY']
    );
  }
};

export class Authentication {
  public static login(userData: {
    email: string;
    password: string;
  }): Promise<Payload<UserLoginResponse>> {
    return AuthenticationApi.login(userData)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static setPassword(
    userData: SetPasswordInput
  ): Promise<Payload<UserLoginResponse>> {
    return AuthenticationApi.setPassword(userData)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static forgetPassword(userData: {
    email: string;
  }): Promise<Payload<void>> {
    return AuthenticationApi.forgetPassword(userData)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
