import { User as UserApi } from '../api/user';
import { UserInterface } from '@orascom/api-interfaces';
import { handleError } from '@orascom/utils';

export class User {
  public static getUserData(): Promise<UserInterface> {
    return UserApi.getUserData()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
