import { useTranslation } from 'react-i18next';
import Numeral from 'numeral';
import styles from './summary.module.scss';
import totalUnitsSoldIcon from '../../assets/icons/total-units-sold.svg';
import totalUnitsRevenueIcon from '../../assets/icons/units-revenue.svg';
import totalRequestsIcon from '../../assets/icons/total-requests.svg';
import conversionRateIcon from '../../assets/icons/conversion-rate.svg';
import SummaryCard from '../summary-card/summary-card';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { useEffect, useState } from 'react';
import {
  OverviewSummary,
  OverviewUserInterestSummary,
  OverviewUserPurchaseSummary,
} from '../../definitions/analytics';
import { Loader } from '@orascom/common-components';
import { errorMessagesHandler } from '@orascom/utils';
import { OptionValue } from '@orascom/api-interfaces';

export interface SummaryProps {
  startDateArg: string;
  endDateArg: string;
  selectedDestinations: OptionValue<string | number>[];
  selectedUnitTypes: OptionValue<string | number>[];
  selectedDestinationsIds: string[];
  selectedUnitTypesIds: string[];
}
export function Summary(props: Readonly<SummaryProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [analyticsOverviewSummary, setAnalyticsOverviewSummary] =
    useState<OverviewSummary>();
  const [
    analyticsOverviewUserPurchaseSummary,
    setAnalyticsOverviewUserPurchaseSummary,
  ] = useState<OverviewUserPurchaseSummary>();
  const [
    analyticsOverviewUserInterestSummary,
    setAnalyticsOverviewUserInterestSummary,
  ] = useState<OverviewUserInterestSummary>();

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };
    Promise.all([
      AnalyticsUtils.getAnalyticsOverviewSummary(params),
      AnalyticsUtils.getAnalyticsOverviewUserPurchaseSummary(params),
      AnalyticsUtils.getAnalyticsOverviewUserInterestSummary(params),
    ])
      .then((res) => {
        setAnalyticsOverviewSummary(res[0]);
        setAnalyticsOverviewUserPurchaseSummary(res[1]);
        setAnalyticsOverviewUserInterestSummary(res[2]);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    props.startDateArg,
    props.endDateArg,
    props.selectedDestinations,
    props.selectedUnitTypes,
  ]);

  if (loading) {
    return <Loader />;
  }

  const unitsRevenueInfo = analyticsOverviewSummary?.total_units_revenues;

  return (
    <div className={styles['wrapper']}>
      <h3 className="shoppes-analytics__sub-title">{t('summarySubtitle')}</h3>
      <h2 className="shoppes-analytics__title">{t('summaryTitle')}</h2>

      <div className="cards__wrapper">
        <SummaryCard
          cardIconSrc={totalUnitsSoldIcon}
          cardTitle={t('totalSoldUnits')}
          cardDesc={`${Numeral(
            analyticsOverviewSummary?.total_sold_units
          ).format('0,0')} Units`}
        />
        <SummaryCard
          cardIconSrc={totalUnitsRevenueIcon}
          cardTitle={t('totalUnitsRevenue')}
          cardDesc={`${Numeral(unitsRevenueInfo?.sales_revenues).format(
            '0,0'
          )} ${unitsRevenueInfo?.currency}`}
        />
        <SummaryCard
          cardIconSrc={totalRequestsIcon}
          cardTitle={t('totalRequests')}
          cardDesc={Numeral(analyticsOverviewSummary?.total_requests).format(
            '0,0'
          )}
        />
        <SummaryCard
          cardIconSrc={conversionRateIcon}
          cardTitle={t('visitorToPurchaseConversionRate')}
          cardDesc={`${Numeral(
            analyticsOverviewUserPurchaseSummary?.users_purchase_rate
          ).format('0,0.000')}%`}
        />
        <SummaryCard
          cardIconSrc={conversionRateIcon}
          cardTitle={t('visitorToInterestConversionRate')}
          cardDesc={`${Numeral(
            analyticsOverviewUserInterestSummary?.interest_conversion_rate
          ).format('0,0.000')}%`}
        />
      </div>
    </div>
  );
}

export default Summary;
