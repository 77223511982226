import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import {
  LoginComponent,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import { useNavigate } from 'react-router-dom';
import { errorMessagesHandler } from '@orascom/utils';
import { Authentication as AuthenticationUtils } from '../../utils/authentication';

export function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userContext = useContext(USER_CONTEXT);
  const [invalidCredentials, setInvalidCredentials] = useState('');

  const submitHandler = (
    values: { email: string; password: string },
    setSubmitting: (arg: boolean) => void
  ) => {
    AuthenticationUtils.login(values)
      .then((res) => {
        toast.success(res.message);
        userContext.setUser?.(res.data.user);
        navigate(ROUTES['Overview'].path);
      })
      .catch((err) => {
        errorMessagesHandler(err);
        setInvalidCredentials(err);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <LoginComponent
      invalidCredentials={invalidCredentials}
      submitHandler={submitHandler}
      forgetPasswordRoute={ROUTES['ForgetPassword'].path}
      dashboardRoute={ROUTES['Overview'].path}
      subtitle={t('shoppersAndPredeliveryAnalytics')}
    />
  );
}

export default Login;
