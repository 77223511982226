import styles from './destination.module.scss';

export function Destination() {
  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>Destination</h1>
    </div>
  );
}

export default Destination;
