import { useTranslation } from 'react-i18next';
import {
  AnalyticsPeriodFilter,
  useAnalyticsPeriodFilter,
  useAnalyticsFilters,
} from '@orascom/broker-sales-man-common-components';
import DestinationPerformance from '../../components/destination-performance/destination-performance';
import UnitTypePerformance from '../../components/unit-type-performance/unit-type-performance';
import Summary from '../../components/summary/summary';
import { useSearchParams } from 'react-router-dom';
import { FilterValue, PeriodType } from '@orascom/api-interfaces';
import { useEffect, useState } from 'react';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { errorMessagesHandler } from '@orascom/utils';

export function Overview() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const durationType = searchParams.get('durationType');

  const [filterDestinations, setFilterDestinations] = useState<FilterValue[]>(
    []
  );
  const [filterUnitTypes, setFilterUnitTypes] = useState<FilterValue[]>([]);

  const { selectedDuration, handleSetSelectedDuration } =
    useAnalyticsPeriodFilter();

  const {
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedUnitTypesIds,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  useEffect(() => {
    if (durationType) {
      handleSetSelectedDuration(durationType as PeriodType);
    }
  }, [durationType]);

  useEffect(() => {
    Promise.all([
      AnalyticsUtils.getAnalyticsFilterDestinations(),
      AnalyticsUtils.getAnalyticsFilterUnitTypes(),
    ])
      .then((res) => {
        setFilterDestinations(res[0]);
        setFilterUnitTypes(res[1]);
      })
      .catch((errors) => errorMessagesHandler(errors.data));
  }, []);

  return (
    <div className="analytics shopper-analytics">
      <div className="analytics__title">
        <h1>{t('overview')}</h1>
      </div>
      <div className="shopper-analytics__content">
        <AnalyticsPeriodFilter
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          handleSelectedDestinations={handleSelectedDestinations}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
          portal="shopper-analytics"
        />

        <Summary
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedUnitTypes={selectedUnitTypes}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedUnitTypesIds={selectedUnitTypesIds}
        />
        <DestinationPerformance
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedUnitTypes={selectedUnitTypes}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedUnitTypesIds={selectedUnitTypesIds}
          selectedDuration={selectedDuration}
        />
        <UnitTypePerformance
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedUnitTypes={selectedUnitTypes}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedUnitTypesIds={selectedUnitTypesIds}
          selectedDuration={selectedDuration}
        />
      </div>
    </div>
  );
}

export default Overview;
