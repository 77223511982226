import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { FilterValue } from '@orascom/api-interfaces';
import styles from './predelivery-analytics.module.scss';
import {
  AnalyticsPeriodFilter,
  useAnalyticsFilters,
} from '@orascom/broker-sales-man-common-components';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { errorMessagesHandler } from '@orascom/utils';
import ReferralsOverview from '../../components/referrals-overview/referrals-overview';
import UnitsResaleOverview from '../../components/units-resale-overview/units-resale-overview';

export function Analytics() {
  const { t } = useTranslation();

  const [filterDestinations, setFilterDestinations] = useState<FilterValue[]>(
    []
  );
  const [filterUnitTypes, setFilterUnitTypes] = useState<FilterValue[]>([]);

  const { handleSelectedDestinations, handleSelectedUnitTypes } =
    useAnalyticsFilters();

  useEffect(() => {
    Promise.all([
      AnalyticsUtils.getAnalyticsFilterDestinations(),
      AnalyticsUtils.getAnalyticsFilterUnitTypes(),
    ])
      .then((res) => {
        setFilterDestinations(res[0]);
        setFilterUnitTypes(res[1]);
      })
      .catch((errors) => errorMessagesHandler(errors.data));
  }, []);

  return (
    <div className="analytics shopper-analytics">
      <div className="analytics__title">
        <h1>{t('analyticsPredelivery')}</h1>
      </div>

      <div className="shopper-analytics__content">
        <AnalyticsPeriodFilter
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          handleSelectedDestinations={handleSelectedDestinations}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
          portal="shopper-analytics"
        />

        <ReferralsOverview />
        <UnitsResaleOverview />
      </div>
    </div>
  );
}

export default Analytics;
