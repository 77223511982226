import { Route } from '@orascom/api-interfaces';
import Login from '../pages/login/login';
import { SetPassword } from '../pages/set-password/set-password';
import ForgetPassword from '../pages/forget-password/forget-password';
import NotFoundPage from '../pages/not-found/not-found';
import Destination from '../pages/destination/destination';
import Analytics from '../pages/analytics/predelivery-analytics';
import Overview from '../pages/overview/overview';
import Updates from '../pages/updates/updates';

export const ROUTES: Record<string, Route> = {
  Overview: {
    path: '/',
    component: Overview,
    public: false,
  },
  Login: {
    path: '/login',
    component: Login,
    public: true,
  },
  ForgetPassword: {
    path: '/forget-password',
    component: ForgetPassword,
    public: true,
  },
  ResetPassword: {
    path: '/set-password',
    component: SetPassword,
    public: true,
  },
  Destination: {
    path: '/destination/:destinationSlug',
    getPath: (destinationSlug: string) => `/destination/${destinationSlug}`,
    component: Destination,
    public: false,
  },
  Analytics: {
    path: '/analytics/:portal',
    getPath: (portal: string) => `/analytics/${portal}`,
    component: Analytics,
    public: false,
  },
  Notifications: {
    path: '/notifications',
    component: Updates,
    public: false,
  },
  /**
   * Always keep this route at the end of the routes object.
   */
  NotFound: {
    path: '*',
    component: NotFoundPage,
    public: true,
  },
};
