import styles from './bar-chart.module.scss';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Legend,
  Tooltip,
} from 'chart.js';
import { Dispatch, SetStateAction } from 'react';
import { Bar } from 'react-chartjs-2';
import { GraphData } from '../../definitions/analytics';

// Register the plugins to the chart
Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Legend,
  Tooltip
);

export interface BarChartProps {
  description: string;
  title: string;
  activeTabs?: string[];
  activeTab?: string;
  setActiveTab?: Dispatch<SetStateAction<string | undefined>>;
  data: {
    labels: string[] | undefined;
    datasets: {
      label: string;
      data: GraphData[];
      backgroundColor: string;
      hoverBackgroundColor: string;
      borderRadius: number;
      borderSkipped: boolean;
      maxBarThickness: number;
      categoryPercentage: number;
      barPercentage: number;
    }[];
  };
  unMaintainAspectRatio?: boolean;
}

export function BarChart(props: Readonly<BarChartProps>) {
  return (
    <div className={styles['wrapper']}>
      <p className="shoppes-analytics__sub-title">{props.description}</p>
      <h2 className="shoppes-analytics__title">{props.title}</h2>
      <div className={`card ${styles['chart']}`}>
        {props.activeTabs && props.setActiveTab && (
          <div className={styles['tabs']}>
            {props.activeTabs.map((tab) => (
              <button
                className={
                  props.activeTab === tab ? styles['active-tab'] : styles['tab']
                }
                key={tab}
                onClick={() => props.setActiveTab?.(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
        )}
        <div
          className={`${styles['bar-chart__container']} ${
            props.unMaintainAspectRatio
              ? styles['bar-chart__container--height']
              : ''
          }`}
        >
          <Bar
            id="bar-chart"
            data={props.data}
            options={{
              responsive: true,
              maintainAspectRatio: props.unMaintainAspectRatio
                ? false
                : window.innerWidth > 480,
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => {
                      const dataPoint = tooltipItem.raw as {
                        x: string;
                        y: number;
                        currency: string;
                      };
                      const currencyLabel = dataPoint.currency
                        ? ` ${dataPoint.currency}`
                        : '';
                      return `${dataPoint.y} ${currencyLabel}`;
                    },
                  },
                },
                legend: {
                  position: 'bottom' as const,
                  align: 'start' as const,
                  labels: {
                    boxWidth: 18,
                    boxHeight: 18,
                    borderRadius: 9,
                    useBorderRadius: true,
                    padding: 30,
                  },
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  grid: {
                    color: 'rgba(21, 44, 91, 0.1)',
                    lineWidth: 0.5,
                  },
                },
              },
              onHover: (event, chartElement) => {
                (event.native?.target as HTMLDivElement).style.cursor =
                  chartElement[0] ? 'pointer' : 'default';
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default BarChart;
