import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import { NotFoundPageUI } from '@orascom/broker-sales-man-common-components';

export function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <NotFoundPageUI
      dashboardRoute={ROUTES['Overview'].path}
      backHome={t('backToHome')}
      error404={t('error404')}
      notFoundPageTitle1={t`notFoundPageTitle1`}
      notFoundPageTitle2={t`notFoundPageTitle2`}
      notFoundPageDesc1={t`notFoundPageDesc1`}
      notFoundPageDesc2={t`notFoundPageDesc2`}
    />
  );
}

export default NotFoundPage;
