export interface ReferralCardProps {
  cardIconSrc: string;
  cardTitle: string;
  cardDesc: string;
}

export function ReferralCard(props: ReferralCardProps) {
  return (
    <div className="referral-card card">
      <div className="summary-card__image">
        <img src={props.cardIconSrc} alt="" />
      </div>

      <div className="summary-card__content">
        <h3 className="summary-card__title">{props.cardTitle}</h3>
        <p className="summary-card__desc">{props.cardDesc}</p>
      </div>
    </div>
  );
}

export default ReferralCard;
