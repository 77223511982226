import totalUnitsSoldIcon from '../../assets/icons/total-units-sold.svg';
import conversionRateIcon from '../../assets/icons/conversion-rate.svg';
import totalUnitsRevenueIcon from '../../assets/icons/units-revenue.svg';
import ReferralCard from '../referral-card/referral-card';
import { useTranslation } from 'react-i18next';

export function ReferralsOverview() {
  const { t } = useTranslation();

  return (
    <div>
      <h3 className="shoppes-analytics__sub-title">{t('referralsSubTitle')}</h3>
      <h2 className="summaryTitle">{t('referralsTitle')}</h2>

      <div className="cards__wrapper">
        <ReferralCard
          cardIconSrc={totalUnitsSoldIcon}
          cardTitle={t('totalReferrals')}
          cardDesc="1,085"
        />
        <ReferralCard
          cardIconSrc={totalUnitsRevenueIcon}
          cardTitle={t('convertedReferrals')}
          cardDesc="164"
        />
        <ReferralCard
          cardIconSrc={conversionRateIcon}
          cardTitle={t('referralsConversionRate')}
          cardDesc="16.09%"
        />
        <ReferralCard
          cardIconSrc={totalUnitsSoldIcon}
          cardTitle={t('referralsSoldUnits')}
          cardDesc="189 Units"
        />
        <ReferralCard
          cardIconSrc={totalUnitsRevenueIcon}
          cardTitle={t('referralsSalesRevenue')}
          cardDesc="1,202,253,654 EGP"
        />
      </div>
    </div>
  );
}

export default ReferralsOverview;
