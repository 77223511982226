import { useTranslation } from 'react-i18next';
import BarChart from '../bar-chart/bar-chart';
import { useEffect, useState } from 'react';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { OptionValue, PeriodType } from '@orascom/api-interfaces';
import { PerformanceGraphs } from '../../definitions/analytics';
import { errorMessagesHandler, getButtonText } from '@orascom/utils';

export interface UnitTypePerformanceProps {
  startDateArg: string;
  endDateArg: string;
  selectedDestinations: OptionValue<string | number>[];
  selectedUnitTypes: OptionValue<string | number>[];
  selectedDestinationsIds: string[];
  selectedUnitTypesIds: string[];
  selectedDuration: PeriodType;
}
export function UnitTypePerformance(props: Readonly<UnitTypePerformanceProps>) {
  const { t } = useTranslation();

  const [unitPerformance, setUnitPerformance] = useState<PerformanceGraphs>();
  const activeTabs = ['Sales Revenue'];
  const [activeTab, setActiveTab] = useState<string>();

  useEffect(() => {
    setActiveTab(activeTabs[0]);
  }, []);

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };

    AnalyticsUtils.getAnalyticsUnitPerformance(params)
      .then((res) => {
        setUnitPerformance(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      });
  }, [
    props.startDateArg,
    props.endDateArg,
    props.selectedDestinations,
    props.selectedUnitTypes,
  ]);

  const data = {
    labels: unitPerformance?.sales_revenue?.map((unit) => unit.name),
    datasets: [
      {
        label: getButtonText(props.selectedDuration),
        data:
          unitPerformance?.sales_revenue?.map((unit) => ({
            x: unit.name,
            y: Number(unit.sales_revenues),
            currency: unit.currency,
          })) || [],
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };
  return (
    <BarChart
      description={t('unitTypePerformanceDescription')}
      title={t('unitTypePerformance')}
      data={data}
      activeTabs={activeTabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
}

export default UnitTypePerformance;
