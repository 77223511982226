import { DESTINATION_SLUG, Route } from '@orascom/api-interfaces';
import {
  SidebarComponent,
  SidebarMenuItem,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import DestinationsIcon from '../../assets/icons/destinations.svg?react';
import DestinationsActiveIcon from '../../assets/icons/destinations-active.svg?react';
import DashboardIcon from '../../assets/icons/dashboard.svg?react';
import DashboardActiveIcon from '../../assets/icons/dashboard-active.svg?react';
import AnalyticsIcon from '../../assets/icons/analytics.svg?react';
import AnalyticsActiveIcon from '../../assets/icons/analytics--active.svg?react';
import { Authentication as AuthenticationAPI } from '../../api/authentication';
import { toast } from 'react-toastify';

export function Sidebar() {
  const [loggingOut, setLoggingOut] = useState(false);
  const location = useLocation();
  const [subMenuAnalyticsOpen, setSubMenuAnalyticsOpen] = useState(false);
  const [subMenuDestOpen, setSubMenuDestOpen] = useState(false);

  const navigate = useNavigate();
  const userContext = useContext(USER_CONTEXT);
  const { t } = useTranslation();

  const isActive = (routeName: Route, id?: string | number) =>
    location.pathname === routeName.path ||
    location.pathname === routeName.getPath?.(id);

  const logoutHandler = () => {
    setLoggingOut(true);
    AuthenticationAPI.logoutUser()
      .then((res) => {
        userContext?.setUser?.(null);
        toast.success(res.message);
        navigate(ROUTES['Login'].path);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => setLoggingOut(false));
  };

  const availableDestinations = [
    {
      id: 3,
      name: 'Owest',
      slug: DESTINATION_SLUG.OWEST,
    },
    {
      id: 14,
      name: 'Makadi Heights',
      slug: DESTINATION_SLUG.MAKADI_HEIGHTS,
    },
    {
      id: 15,
      name: 'El-Gouna',
      slug: DESTINATION_SLUG.GOUNA,
    },
  ];
  return (
    <SidebarComponent
      subtitle={t('shoppersAndPredeliveryAnalytics')}
      loggingOut={loggingOut}
      logoutHandler={logoutHandler}
    >
      <SidebarMenuItem
        route={ROUTES['Overview']}
        icon={<DashboardIcon />}
        activeIcon={<DashboardActiveIcon />}
        isActive={isActive(ROUTES['Overview'])}
        label={t('overview')}
      />
      <SubMenu
        label={t('analytics')}
        icon={
          location.pathname.includes('analytics') ? (
            <AnalyticsActiveIcon />
          ) : (
            <AnalyticsIcon />
          )
        }
        active={location.pathname.includes('analytics')}
        open={subMenuAnalyticsOpen}
        onOpenChange={() => setSubMenuAnalyticsOpen(!subMenuAnalyticsOpen)}
      >
        <MenuItem
          component={<Link to={ROUTES['Analytics'].getPath?.('shoppers')} />}
          active={isActive(ROUTES['Analytics'], 'shoppers')}
        >
          {t('shoppers')}
        </MenuItem>
        <MenuItem
          component={<Link to={ROUTES['Analytics'].getPath?.('predelivery')} />}
          active={isActive(ROUTES['Analytics'], 'predelivery')}
        >
          {t('predelivery')}
        </MenuItem>
      </SubMenu>
      <SubMenu
        label={t('destinations')}
        icon={
          location.pathname.includes('destination') ? (
            <DestinationsActiveIcon />
          ) : (
            <DestinationsIcon />
          )
        }
        active={location.pathname.includes('destination')}
        open={subMenuDestOpen}
        onOpenChange={() => setSubMenuDestOpen(!subMenuDestOpen)}
      >
        {availableDestinations.map((destination) => (
          <MenuItem
            key={destination.id}
            component={
              <Link to={ROUTES['Destination'].getPath?.(destination.slug)} />
            }
            active={isActive(ROUTES['Destination'], destination.slug)}
          >
            {destination.name}
          </MenuItem>
        ))}
      </SubMenu>
    </SidebarComponent>
  );
}
export default Sidebar;
