import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ROUTES } from '../../api/routes';
import { Authentication as AuthenticationUtils } from '../../utils/authentication';
import { ForgetPasswordComponent } from '@orascom/broker-sales-man-common-components';
import { errorMessagesHandler } from '@orascom/utils';

export function ForgetPassword() {
  const { t } = useTranslation();
  const [isReset, setIsReset] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitHandler = (values: { email: string }) => {
    setIsSubmitting(true);
    AuthenticationUtils.forgetPassword(values)
      .then(() => {
        setIsReset(true);
      })
      .catch((err) => errorMessagesHandler(err))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <ForgetPasswordComponent
      subtitle={t('shoppersAndPredeliveryAnalytics')}
      loginRoute={ROUTES['Login'].path}
      dashboardRoute={ROUTES['Overview'].path}
      isReset={isReset}
      submitHandler={submitHandler}
      isSubmitting={isSubmitting}
    />
  );
}

export default ForgetPassword;
