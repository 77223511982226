import * as ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';
import * as Sentry from '@sentry/react';

import App from './app/app';
import { ToastContainer } from 'react-toastify';
import i18next from '../i18n';
import { I18nextProvider } from 'react-i18next';
import React from 'react';

const sentryEnv = process.env['NX_SENTRY_ENV'];

Sentry.init({
  dsn: 'https://c0cd8a12cde44f99bbea9eb3a217ab7e@sentry.robustastudio.com/49',
  tracesSampleRate: 1.0,
  enabled: sentryEnv !== 'development',
  environment: sentryEnv,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLDivElement
);

const language = i18next.language;

root.render(
  <BrowserRouter basename={process.env['NX_SHOPPER_ANALYTICS_PATH'] ?? '/'}>
    <I18nextProvider i18n={i18next}>
      <ProSidebarProvider>
        <App />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop
          closeOnClick
          rtl={language === 'ar'}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </ProSidebarProvider>
    </I18nextProvider>
  </BrowserRouter>
);
