import { Network } from './network';
import { Payload, UserInterface } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';

export class User {
  public static getUserData(): Promise<Payload<UserInterface>> {
    const url = `${process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT']}${ENDPOINTS.getUserData.url}`;
    return Network.fetch(url, {
      method: ENDPOINTS.getUserData.method,
    });
  }
}
