import { parseError } from '@orascom/utils';
import { Analytics as AnalyticsApi } from '../api/analytics';
import {
  OverviewSummary,
  OverviewUserInterestSummary,
  OverviewUserPurchaseSummary,
  PerformanceGraphs,
} from '../definitions/analytics';
import { FilterParams, FilterValue } from '@orascom/api-interfaces';

export class Analytics {
  public static getAnalyticsUnitPerformance(
    params: FilterParams
  ): Promise<PerformanceGraphs> {
    return AnalyticsApi.getAnalyticsUnitPerformance(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsDestinationPerformance(
    params: FilterParams
  ): Promise<PerformanceGraphs> {
    return AnalyticsApi.getAnalyticsDestinationPerformance(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsOverviewUserPurchaseSummary(
    params: FilterParams
  ): Promise<OverviewUserPurchaseSummary> {
    return AnalyticsApi.getAnalyticsOverviewUserPurchaseSummary(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsOverviewUserInterestSummary(
    params: FilterParams
  ): Promise<OverviewUserInterestSummary> {
    return AnalyticsApi.getAnalyticsOverviewUserInterestSummary(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsOverviewSummary(
    params: FilterParams
  ): Promise<OverviewSummary> {
    return AnalyticsApi.getAnalyticsOverviewSummary(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsFilterDestinations(): Promise<FilterValue[]> {
    return AnalyticsApi.getAnalyticsFilterDestinations()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsFilterUnitTypes(): Promise<FilterValue[]> {
    return AnalyticsApi.getAnalyticsFilterUnitTypes()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }
}
