import { Network } from './network';
import { FilterParams, FilterValue, Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { constructURL } from '@orascom/utils';
import {
  OverviewSummary,
  OverviewUserInterestSummary,
  OverviewUserPurchaseSummary,
  PerformanceGraphs,
} from '../definitions/analytics';

export class Analytics {
  public static getAnalyticsUnitPerformance(
    params: FilterParams
  ): Promise<Payload<PerformanceGraphs>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsUnitPerformance.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsUnitPerformance.method,
    });
  }

  public static getAnalyticsDestinationPerformance(
    params: FilterParams
  ): Promise<Payload<PerformanceGraphs>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsDestinationPerformance.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsDestinationPerformance.method,
    });
  }

  public static getAnalyticsOverviewSummary(
    params: FilterParams
  ): Promise<Payload<OverviewSummary>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsOverviewSummary.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsOverviewSummary.method,
    });
  }

  public static getAnalyticsOverviewUserPurchaseSummary(
    params: FilterParams
  ): Promise<Payload<OverviewUserPurchaseSummary>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsOverviewUserPurchaseSummary.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsOverviewUserPurchaseSummary.method,
    });
  }

  public static getAnalyticsOverviewUserInterestSummary(
    params: FilterParams
  ): Promise<Payload<OverviewUserInterestSummary>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsOverviewUserInterestSummary.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsOverviewUserInterestSummary.method,
    });
  }

  public static getAnalyticsFilterDestinations(): Promise<
    Payload<FilterValue[]>
  > {
    return Network.fetch(
      `${process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT']}${ENDPOINTS.getAnalyticsFilterDestinations.url} `,
      {
        method: ENDPOINTS.getAnalyticsFilterDestinations.method,
      }
    );
  }

  public static getAnalyticsFilterUnitTypes(): Promise<Payload<FilterValue[]>> {
    return Network.fetch(
      `${process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT']}${ENDPOINTS.getAnalyticsFilterUnitTypes.url} `,
      {
        method: ENDPOINTS.getAnalyticsFilterUnitTypes.method,
      }
    );
  }
}
