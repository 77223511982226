// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Loader, ScrollToTop } from '@orascom/common-components';
import { ROUTES } from '../api/routes';
import styles from './app.module.scss';

import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { UserInterface } from '@orascom/api-interfaces';
import { useEffect, useMemo, useState } from 'react';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';
import { User as UserUtils } from '../utils/user';
import Sidebar from '../components/sidebar/sidebar';

export function App() {
  const [user, setUser] = useState<UserInterface | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);

  const navigate = useNavigate();
  const location = useLocation();
  const availableRoutes = Object.values(ROUTES)
    .map((route) => {
      if (!route.public && !user) {
        return;
      }

      const Component = route.component;

      return (
        <Route key={route.path} path={route.path} element={<Component />} />
      );
    })
    .filter((route) => route !== null);

  useEffect(() => {
    if (!user) {
      if (
        localStorage.getItem(
          process.env['NX_SHOPPER_ANALYTICS_ACCESS_TOKEN_KEY'] as string
        )
      ) {
        UserUtils.getUserData()
          .then((res) => {
            setUser(res);
          })
          .catch(() => {
            if (location.pathname !== ROUTES['ResetPassword'].path) {
              navigate(ROUTES['Login'].path);
            }
          })
          .finally(() => setLoadingUser(false));
      } else {
        setLoadingUser(false);
        if (location.pathname !== ROUTES['ResetPassword'].path) {
          navigate(ROUTES['Login'].path);
        }
      }
    } else {
      setLoadingUser(false);
    }
  }, [user]);

  const userContextValue = useMemo(
    () => ({
      user,
      setUser,
      isLoading: loadingUser,
    }),
    [user, loadingUser]
  );

  // This is necessary because the current implementation forces one layout,
  // and we need to ensure the NotFound route doesn't render the sidebar,
  // without breaking the existing style logic.
  const isNotFoundRoute = !Object.values(ROUTES).some(
    ({ path }) =>
      path === location.pathname ||
      (path.includes(':') &&
        new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`).test(
          location.pathname
        ))
  );

  const showSidebar = !isNotFoundRoute && user;

  if (loadingUser && !isNotFoundRoute) {
    return <Loader />;
  }

  return (
    <USER_CONTEXT.Provider value={userContextValue}>
      <main className={`${user ? 'container container--flex' : 'container'}`}>
        {showSidebar && <Sidebar />}
        <ScrollToTop />
        <section className={styles['side-section']}>
          <Routes>{availableRoutes}</Routes>
        </section>
      </main>
    </USER_CONTEXT.Provider>
  );
}

export default App;
